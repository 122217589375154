(function ($) {    

  var initSlick = function($block) {   
    $block.find('.ets_slick_slides').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      dots : true,
      draggable : false,
      arrows : false,
      pauseOnHover:false
    });
  }

  // Frontend
  $(document).ready(function () { 
    $('.ets_slick--1').each(function(){
      initSlick($(this));
    });  
  });

  // Gutenberg Editor
  if( window.acf ) {
    window.acf.addAction( 'render_block_preview/type=banner', initSlick);
  }
})(jQuery);